.editor-onboarding-album-title-wrapper {
  padding: map-get($spacers, 3);

  .album-title {
    justify-content: start;
    max-width: $sidebar-width;

    #albumTitleEditButton {
      margin-right: map-get($spacers, 2);
    }
  }
}
