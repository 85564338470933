.draft-root {
    overflow: visible;

    &.editing {
        cursor: auto;
    }

    .DraftEditor-root {
        font-size: 0;
    }
     
    // Workaround to hide selected text when the element is being unselected
    .public-DraftEditor-content[contenteditable='false'] {
        user-select: none !important;
    }

}
