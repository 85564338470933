.handles {
    &.frame {
        pointer-events: none;
    }

    &.operations {
        pointer-events: all;
    }

    * {
        vector-effect: non-scaling-stroke;
    }

    .handle {
        fill: none;
    }

    .border {
        pointer-events: none;
        fill: none;
        stroke: #004C97;
        stroke-width: 1px;
    }

    .resize {
        fill: #fff;
        stroke: #004C97;
    }

    .rotate.topLeft {
        cursor: url(/assets/images/rotate-0.svg) 16 16, auto;
    }

    .rotate.topRight {
        cursor: url(/assets/images/rotate-1.svg) 16 16, auto;
    }

    .rotate.bottomRight {
        cursor: url(/assets/images/rotate-2.svg) 16 16, auto;
    }

    .rotate.bottomLeft {
        cursor: url(/assets/images/rotate-3.svg) 16 16, auto;
    }

    .scale {
        fill: #fff;
        stroke: #004C97;
        stroke-width: 1px;
    }

    .scale.topLeft {
        cursor: se-resize;
    }

    .scale.topRight {
        cursor: sw-resize;
    }

    .scale.bottomRight {
        cursor: nw-resize;
    }

    .scale.bottomLeft {
        cursor: ne-resize;
    }

    .resize.topLeft, .resize.bottomRight {
        cursor: se-resize;
    }

    .resize.topRight, .resize.bottomLeft {
        cursor: sw-resize;
    }

    .resize.top, .resize.bottom {
        cursor: ns-resize;
    }

    .resize.left, .resize.right {
        cursor: ew-resize;
    }

    //  For sticker and image-manipulation or during editing text: use green color to indicate "inside" transform
    &.operations.inside.type-Text, &.operations.inside.type-Image {
        .resize {
            stroke: #88d14c;
        }

        .border {
            stroke: #88d14c;
        }

        .scale {
            stroke: #88d14c;
        }
    }
}
