/* Hide hover tooltips on mobile. */
.tooltip {
  @media (max-width: $breakpoint-desktop) {
    display: none !important;
  }
}

/* Desktop toolbar variables */
$toolbarBackground: #fff;
$toolbarColor: #2f323a;
$toolbarColorSelected: #004c97;
$gap: 0.5em;
$menu-height: 11.5em;

/* Mobile toolbar variables */
$mobile-menu-height: 13.5em;

.rc-color-picker-panel {
  z-index: 11;
}

.element-toolbar-mobile {
  display: flex;
  justify-content: center;

  width: 100%;
  background-color: $color-background;
  overflow-x: scroll;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdown-menu {
    position: fixed !important;
    bottom: $mobile-footer-menu-height !important;
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    border-top: 1px solid #eeeeee !important;
    padding: 1rem 0 !important;

    background-color: $color-background;
    box-shadow: none !important;
    transform: none !important;

    &.scrollable {
      overflow-x: scroll;
    }
  }
}

.element-toolbar {
  background: $toolbarBackground;
  z-index: 10;

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.element-toolbar,
.element-toolbar-mobile {
  color: $toolbarColor;
  border-radius: 0.25rem;

  /* Common settings */

  .btn {
    transition: none !important;

    .icon {
      width: 20px;
      height: 20px;

      > div,
      svg {
        color: $toolbarColor;
        width: 100%;
        height: 100%;
        max-width: 22px;
      }
    }
  }

  .dropdown-menu {
    border-radius: 0.25rem;
    border: none;
    min-width: 0;
    max-height: $menu-height;
  }

  .btn:not(.button-swatch),
  .btn-primary:not(.button-swatch) {
    background-color: transparent;
    line-height: 1;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &,
    &:focus,
    &:hover,
    &:active {
      color: $toolbarColor;
      border: none;
      box-shadow: none !important;
      border-color: transparent !important;
      background-color: transparent !important;
    }

    &.active {
      color: $toolbarBackground;
      background-color: $toolbarColorSelected !important;
      .icon > div,
      svg {
        color: $toolbarBackground;
      }
    }

    &:disabled {
      opacity: 0.2 !important;
    }
  }

  .panel-container {
    /* Inside a panel-container, the dropdown-menu is transparent and
        may contain multiple panels which themselves mimic the style
        of the dropdown-menu */
    .dropdown-menu {
      padding: 0;
      background: transparent;
      box-shadow: none !important;
      display: flex;
      flex-wrap: nowrap;
      gap: $gap;

      .panel {
        background: $toolbarBackground;
        box-shadow: 0 0.5rem 1rem rgba(27, 28, 29, 0.15) !important;
        border-radius: 0.25rem;
      }
    }
  }

  /* Settings for the individuals controls */

  .color-picker {
    @media (max-width: $breakpoint-desktop) {
      z-index: -1;
    }
  }

  .font-input,
  .size-input {
    .dropdown-menu {
      height: $mobile-menu-height;

      @media (min-width: $breakpoint-desktop) {
        height: $menu-height;
      }

      max-height: none;
      border-top: none !important;
    }
  }

  .align-text,
  .size-input {
    .dropdown-menu {
      flex-direction: column;
      border-top: none !important;
    }
    .panel {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  .align-text {
    .panel {
      overflow: hidden;
    }
  }

  .text-symbol {
    .dropdown-menu {
      justify-content: start;

      @media (min-width: $breakpoint-desktop) {
        justify-content: center;
      }
    }
  }

  .size-input {
    .size-list {
      flex-direction: column;
    }
    .autosize-input {
      margin-right: -15px;
    }
  }

  .font-input {
    .dropdown-menu {
      flex-direction: row;
    }

    .basic-fonts {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: $gap;

      .toggle-additional-fonts {
        color: $toolbarColorSelected;
        justify-content: flex-end;
      }

      .panel {
        overflow: hidden;
      }
    }

    &.dropup {
      .basic-fonts {
        justify-content: flex-end;
      }
    }

    .additional-fonts {
      background: $toolbarBackground;
      color: $toolbarColor;
      border-radius: 0.25rem;
      height: $menu-height;
    }
  }

  .unlink-sticker {
    .icon {
      width: 16px;
    }
  }

  .stroke-input,
  .color-input {
    .stroke-width {
      min-width: 28px;
      display: inline-block;
      text-align: right;
    }

    .preview {
      display: inline-block;
      width: 20px;
      height: 20px;
      position: relative;

      svg {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  .opacity-input {
    .opacity-value {
      min-width: 40px;
      display: inline-block;
      text-align: right;
    }
  }

  .stroke-width-input,
  .opacity-input {
    .form-control-range {
      width: 200px;
      margin: 0.25rem 1rem;
    }
  }

  .stroke-input + .stroke-width-input,
  .stroke-width-input + .opacity-input {
    > .btn {
      padding-right: 0.25rem;
    }
  }

  .seperator {
    border-left: 1px solid $border-color;
  }

  .font-color {
    svg {
      overflow: visible;
      rect {
        filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2));
      }
    }
  }
}

.workspace-tool-menu {
  position: absolute;
  right: 1rem;
  top: 40%;

  .btn {
    svg {
      max-width: 20px;
      max-height: 20px;
    }
    &.active {
      color: $primary !important;
      border-color: white !important;
    }
  }
}

.toolbar-notification {
  bottom: 80vh;
  font-size: 15px;
  @media screen and (min-width: $breakpoint-desktop) {
    height: 40px;
  }
}
