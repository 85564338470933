.sticker-upload-form {
  height: 100vh;
  height: -webkit-fill-available;

  .form-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 990px;

    .header {
      padding: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
    }

    .body {
      padding: 0 2rem;
      flex: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }

    .footer {
      padding: 2rem;
    }
  }

  .photo-preview {
    max-width: 100%;
  }

  .logo {
    width: 110px;
  }
}

.file-input {
  cursor: pointer;
  
  input {
    display: none;
  }

  svg {
    color: $primary;
    width: 50px;
    height: 50px;
  }
}
