.element {
    pointer-events: all;

    .highlight-box {
        pointer-events: none;
    }
}

.pointer-events-none {
    pointer-events: none;
}

.drop-indication {
    fill: #004C97;
    fill-opacity: 0.2;
    stroke: #004C97;
    stroke-width: 2px;
    stroke-linecap: square;
}

.comment {
    position: relative;

    .icon {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
    }

    foreignObject {
        overflow: visible;
    }

    textarea {
        position: absolute;
        left: 15px;
        top: 15px;
        width: 200px;
        resize: none;
    }
}
