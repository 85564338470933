.checkout {
  .checkout-summary {
    border: 1px solid $primary;
    border-radius: 4px;

    &__header {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $primary;
      margin-bottom: 21px;
    }

    &__title,
    &__additional {
      font-size: 15px;
      line-height: 21px;
    }

    &__text {
      font-size: 12px;
      line-height: 140%;
      max-width: 200px;
    }

    &__quantity {
      font-size: 15px;
      line-height: 140%;
    }
  }

  .enhanced-checkbox {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    transition: border-color 0.5s ease, background-color 0.5s ease;

    &--checked {
      background: rgba(5, 163, 243, 0.05);
      border-color: $primary;
    }

    &__image {
      object-fit: cover;
      max-height: 125px;
    }
  }

  .checkout-form {
    &-release-date-input {
      @media (min-width: $breakpoint-desktop) {
        width: 25%;
      }
    }

    &__range {
      background: rgba(5, 163, 243, 0.05);
      border-radius: 4px;

      &__headline {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
      }

      &__label {
        font-size: 15px;
        line-height: 21px;
      }
    }

    &-submit {
      position: relative;

      .icon {
        z-index: 2;
        position: absolute;
        top: -20px;
        right: -15px;
      }
    }
  }
}
