html, body {
  height: 100%;
}

h1, h2 {
  font-weight: 500;
}

.card {
  border: 0;

  .card-header {
    border-bottom: 0;
  }
}

.alert-primary {
  background-color: $primary;
  color: white;
}

.modal {
  .modal-header {
    border: 0;
  }

  .modal-content {
    border: 0;
  }
}

.dropdown-menu {
  @extend .shadow;
  border-color: none;
}

.no-caret:after {
  display: none;
}

.navbar-brand {
  path {
    color: black;
  }
}

.hsds-beacon > .Beacon {
  > div {
    left: 20px !important;
    
    @media (max-width: $breakpoint-desktop) {
      left: auto !important;
      bottom: 20px;
    }
  }

  .BeaconFabButtonFrame {
    display: unset;
    
    @media (max-width: $breakpoint-desktop) {
      display: none;
    }
  } 
  
}

// https://github.com/react-bootstrap/react-bootstrap/issues/1588
button[disabled] {
  pointer-events: none;
}

// https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336
.flex-grow-1 {
  min-height: 0;
}

.nav-tabs .nav-link {
  transition: border-color .5s;

  &.active {
    border-color: transparent;
    border-bottom-color: $primary;
    border-bottom-width: 3px;
    color: $primary;
  }
}

input[type=date] {
  position: relative;
  text-align: right;
}

input[type=date]:before {
  color: #707982;
  content: attr(placeholder) !important;
  left: 0.7rem;
  position: absolute;
}

.alert-link {
  font-weight: 400;
}
