$color-background: #F5F5F5;
$color-light-grey: #f1f1f1;
$color-medium-gray: #848484;
$color-dark-gray: #272727;
$light: #F5F5F5;
$lighter: lighten($light, 2%);

// Not prefixed because they are used by Semantic
$red: #E0004D;
$blue: #050505;
$primary: #004C97;
$success: #C1D833;
$muted: #6c757d;
$info: #b1c9e8;

$theme-colors: (
    "info": $info,
    "danger": $red,
    "success": $green
);

.bg-lighter {
    background-color: $lighter;
}
