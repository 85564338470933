.timeline {
  width: 100%;
  position: relative;
  padding: 1rem 0 1rem;

  .timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 67px;
    height: 100%;
    width: 4px;
    background: $light;
    border-radius: 0.25rem;
  }

  .timeline-element {
    position: relative;
    margin: 0 0 1rem;
  }

  .timeline-element-icon {
    position: absolute;
    top: 0;
    left: 60px;
  }

  .timeline-element-icon .badge-dot-xl {
    box-shadow: 0 0 0 5px #fff;
  }

  .badge-dot-xl {
    width: 18px;
    height: 18px;
    position: relative;
  }

  .badge-dot-xl::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 0.25rem;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -5px 0 0 -5px;
    background: #fff;
  }

  .timeline-element-content {
    position: relative;
    margin-left: 90px;
    font-size: 1rem;
  }

  .timeline-element-content .timeline-title {
    font-size: 1rem;
    text-transform: uppercase;
    margin: 0 0 0.5rem;
    padding: 2px 0 0;
    font-weight: bold;
  }

  .timeline-element-content .timeline-element-date {
    display: block;
    position: absolute;
    left: -100px;
    top: 0;
    padding-right: 10px;
    text-align: right;
    color: $secondary;
    font-size: 0.9rem;
    white-space: nowrap;
  }

  .timeline-element-content:after {
    content: '';
    display: table;
    clear: both;
  }
}
