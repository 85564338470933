$sticker-width: 150px;
$sticker-height: $sticker-width * 1.4;

.sticker-uploads-modal {
  .stickers-list {
    height: 480px;
    overflow: auto;
  }

  .sticker-container {
    width: $sticker-width;
    height: $sticker-height;

    &.double {
      width: $sticker-width * 2;
    }
  
    .sticker-info-container {
      height: $sticker-height * 0.2;

      .sticker-info {
        font-size: 12px;
        padding-top: 5px;
      }

      .sticker-position {
        opacity: 0.5;
      }  
    }
  }

  .sticker-uploads-modal-footer {
      justify-content: space-between;
      display: flex;
  }

  @media (max-width: $breakpoint-desktop) { 
    .stickers-list {
      height: auto;
      max-height: 50vh;
    }

    .sticker-form {
      width: 100% !important;
    }

    .sticker-uploads-modal-footer {
      flex-direction: column;

      .btn + .btn {
        margin-top: .5rem;
      }
    }
  }
}

