/*@import "assets/fonts/fonts.css";*/

.ui.inverted.segment {
  background: $color-background;
}

.ui.tabular.menu + .attached:not(.top).segment.editor-control--panes {
  margin-top: 1rem;
}

.sticker-icon {
  min-width: 30px;
  height: 30px;
  display: inline-block;
  object-fit: contain;
}

.sticker-input {
  display: flex;
  align-items: center;
  padding: 0.5rem;

  .name {
    flex-grow: 1;
    width: 0;
  }
}

.sticker-input .ui[class*='right floated'].button {
  margin-left: auto;
}

.sticker-input .plus.icon {
  cursor: pointer;
}

.icon-margin,
.sticker-icon {
  margin-right: 10px;
}

.spacer {
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
}

.drag-preview {
  border-top: 1px solid #dbdbdb;
}

.draggable {
  -webkit-transition: padding 0.1s linear;
  transition: padding 0.1s linear;
  padding-top: 0;
  padding-bottom: 0;
}

.customicon {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: inline-block !important;
  width: 16px !important;
  height: 16px !important;
  margin: 0 !important;
}

body {
  color: #000;
  font-family: $fontName, sans-serif !important;
}

a, a:hover {
  text-decoration: none !important;
}

.ui.button {
  font-weight: normal;
  background: #fff;
  color: #282e35;
}

.dnd-hover {
  border-bottom: 1px dashed black;
}

.ui.page.modals.dimmer.transition.visible.active {
  display: flex !important;
}

svg,
.overlay {
  font-family: $fontName, Arial, sans-serif;
}

.ui.list > .item.active {
  background: #f3f4f5;
}

$color-highlight: #000;

.highlight-selected {
  border: 3px solid white !important;
  outline: 3px solid black !important;
}

.highlight-none {
  /* only for spacing */
  border: 3px solid transparent !important;
  outline: 3px solid transparent !important;
}

.highlight-grey {
  /* only for spacing */
  border: 3px solid white !important;
  outline: 3px solid #d5d5d5 !important;
}

.highlight-pulse {
  box-shadow: 0 0 0 $color-highlight;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($color-highlight, 0.9);
  }
  99% {
    box-shadow: 0 0 0 10px rgba($color-highlight, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($color-highlight, 0);
  }
}

img.svg {
  background: #d5d5d5 !important;
}

.background-none {
  background: url(/assets/images/no-background.svg) no-repeat !important;
  background-size: contain;
}

.highlight-shadow {
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
}

.button-swatch {
  display: inline-block;
  margin: 5px !important;
  padding: 0 !important;
  border-radius: 0 !important;
  width: 30px !important;
  height: 30px !important;
}

.spread-grid {
  pointer-events: none;
}

.ui.label {
  background-color: transparent;
}

.ui.menu.vertical {
  border-radius: 0 !important;
}

.label.entry {
  float: none !important;
  margin-left: 0 !important;
}

.templates-panel {
  min-height: 500px;
}

.ui.grid > .row {
  padding: 0 !important;
}

.tabs {
  padding-left: 80px;
  width: 510px;
  box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.06);
  z-index: 1;
  .grid {
    .column {
      padding-top: 4px !important;
      &:first-child {
        padding: 0 !important;
        width: 0 !important;

        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: -5rem;
          top: 0;
          bottom: 0;
          right: 0;
          background: $color-background;
        }

        .ui.vertical.tabular.menu {
          top: 4rem;
          right: 5rem;
          position: absolute;

          .item {
            height: 4rem;
            width: 5rem;
            border-top-left-radius: 2rem !important;
            border-bottom-left-radius: 2rem !important;
            justify-content: center;
            align-items: center;
            border: none !important;
            margin: 0 !important;
            color: rgba($color-dark-gray, 0.5);
            display: flex;
            flex-direction: column;

            &.active {
              color: $color-dark-gray;
            }

            & > i.icon {
              margin: 0 0 8px 0;
            }

            & > .title {
              text-transform: uppercase;
              font-size: 80%;
              text-align: center;
              line-height: 120%;
            }
          }
        }
      }
      &:last-child {
        padding-left: 2px;

        background: #fff;
        width: 100% !important;
      }
    }

    .menu {
      border: none !important;
    }

    .ui.celled.list > .item {
      border-top: none;
    }

    .ui.segment {
      padding-top: 0;
    }
  }

  & .list .item {
    margin-bottom: 10px;
  }

  & > .main {
    display: flex;
    flex-direction: column;
  }

  & > .main > .menu {
    flex-basis: 0;
  }

  & .ui.table:last-child {
    margin: 0;
  }

  .ui.horizontal.list:not(.celled) > .item:first-child,
  & .ui.list .item {
    vertical-align: top;
    padding: 0;
    margin: 5px !important;
  }

  & .ui.table.sections .ui.table td {
    padding: 2px;
  }

  > .flex,
  > .grid {
    flex-grow: 1;
  }

  > .tab {
    flex-grow: 1;
  }

  .ui.tab.active {
    display: flex !important;
  }
}

.ui.grid > .row.stretched {
  flex-grow: 1;
}

.flex {
  display: flex;
  flex-direction: column;
}

.flex.vertical {
  flex-direction: row;
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.ui.grid {
  margin: 0 !important;
}

.ui.tab.active.flex,
.ui.tab.open.flex {
  display: flex !important;
}

.ui.secondary.menu .ui.secondary.menu:not(.vertical) .item {
  padding: 2px 0;
}

.ui.table {
  thead {
    tr:first-child {
      border-radius: 8px 8px 0 0;
      border: 0;
    }

    th {
      background: $color-light-grey;
      border: 0;
    }
  }

  tbody {
    td {
      font-family: $fontName, sans-serif;
      font-weight: $lightWeight;
    }
  }
}

.filter-input .item {
  padding: 0 !important;
  margin: 0 !important;
}

.filter-input .item label {
  line-height: 40px;
  min-width: 40px;
}

.usage {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #264c59;
  color: white;
  border-radius: 20px;
  padding: 5px 8px;
}

.toolbar {
  min-height: 60px;

  .item,
  .menu {
    flex-wrap: wrap;
    max-width: 100%;
  }

  .horizontally.fitted.item {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }

  .item > div,
  [role='listbox'] {
    margin-right: 1em;
  }

  .ui.selection.dropdown {
    min-width: 0;
  }

  .richtext {
    .ui.button {
      > .icon:not(.button) {
        opacity: 0.4;
      }
      &.active > .icon:not(.button) {
        opacity: 1;
      }
    }
  }
}

.editor-control--panes-wrapper {
  clear: both;

  .ui.secondary.menu {
    overflow-x: scroll;
    margin: 1em 1em -1em 1em;
    padding-bottom: 1em;
    padding-top: 1em;
    box-shadow: inset -8px 0px 5px -7px rgba(0, 0, 0, 0.3);
  }
}

.ui.attached.segment.tab {
  border: none;
}

.global-navigation--menu.ui.secondary.menu {
  min-height: 50px;
  height: 50px;

  .item {
    padding-left: 0;
    padding-right: 0;

    button:not(.primary) {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
}

/*******************************
         Theme Overrides
*******************************/

.ui.icon.button {
  &:not(.compact) {
    padding-left: 24px;
    padding-right: 24px;
  }
  &.compact {
    padding: 0;
    min-width: 35px;
    min-height: 35px;
  }
}

.ui.form input[type='text'] {
  font-size: inherit;
}

.ui.primary.button,
.ui.primary.buttons .button {
  background: $blue;
  font-weight: bold;
  min-height: 40px;
}

.button.primary:after {
  position: absolute;
  box-shadow: 10px 10px 10px inherit;
}

div[draggable='true'] > .ui.button {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  background: white;

  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
    transition: 0.5s;
  }
}

.ui.button.primary:disabled {
  background: #cacbcd;
}

hr {
  background: $lightGrey;
  height: 1px;
  border: 0;
}

i.huge.icon,
i.huge.icons {
  min-width: 5rem;
  min-height: 5rem;
}

.ui.menu:not(.vertical) > .menu {
  width: 100%;
}

.sticker-input > button {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background: none;
  text-align: left;
  font-family: $fontName, sans-serif;
  outline: none;

  > {
    .number {
      display: inline-block;
      font-weight: bold;
      width: 30px;
    }
    .image {
      $scale: 0.7;
      display: inline-block;
      border: 2px solid #aaa;
      padding: 2px;
      width: 50px * $scale;
      height: 70px * $scale;
      margin-right: 20px;
      &.double {
        width: 100px * $scale;
      }
      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
      .background-none {
        width: 100%;
        height: 100%;
        background-size: contain;
      }
    }
  }

  .input {
    width: 100%;
  }
}

.menu.field {
  display: inline-flex;
  justify-content: left;
  min-width: 60px;
}

.ui.vertical.menu .item.flex {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.item.job {
  background: #a4bfc8;

  &.job-queued {
    background: #a4bfc8;
  }
  &.job-finished {
    background: #8ec89d;
  }
  &.job-exists {
    background: #aaa;
  }
  &.job-working {
    background: #f4ebd0;
  }
  &.job-error {
    background: #f2af9e;
  }

  border-radius: 5px;
  display: flex;
  flex-direction: row;

  padding: 1rem !important;
  align-items: center;

  .title {
    font-weight: bold;

    .time {
      margin-left: 0.5rem;
      font-weight: normal;
      opacity: 0.6;
    }
  }

  .first,
  .last {
    width: 50px;
    justify-content: center;
    display: flex;
  }

  .middle {
    flex-grow: 1;
  }

  .ui.progress {
    margin: 0.5rem 0 1.5rem;
    > .label {
      margin-top: 0.5rem;
    }
  }

  .eta {
    right: 0;
    color: #fff;
    z-index: 1;
  }

  .links {
    margin-top: 0.5rem;
  }
}

.tabs .ui.list .item.job {
  margin: 0 5px 5px 0 !important;
}

.create-job,
.text-replace,
.builder {
  font-size: 12.5px !important;
  label {
    min-width: 200px;
    font-weight: bold;
    margin-top: 1.8px;
  }
}

.create-job-select {
  border-color: rgba(34, 36, 38, 0.15);
  width: 240px;
  padding: 5px;
}

.btn-group {
  .ui.button {
    color: rgba(0, 0, 0, 0.3);
    &.active {
      color: rgba(0, 0, 0, 1);
    }
  }
}

.element-button {
  margin-bottom: 10px;
}

table .ui.horizontal.list {
  padding: 0 !important;
}

.item.spinner {
  width: 4rem;
}

.page-margins {
  margin-top: 20px;
  width: 200px;
  height: 100px;
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;

  $width: 60px;

  > * {
    position: absolute !important;
    width: $width;
  }

  .top {
    top: 0;
    left: 50%;
    margin-left: -$width * 0.5;
  }

  .left {
    left: 0;
  }
  .right {
    right: 0;
  }

  .bottom {
    bottom: 0;
    left: 50%;
    margin-left: -$width * 0.5;
  }
}

.flex {
  display: flex;

  &.end {
    align-items: flex-end;
  }
}

#fakeInput {
  display: none;
}

.elements-tree {
  box-shadow: 0px 0px 20px #0000001f;
  position: absolute;
  left: 15px;
  top: 130px;
  z-index: 5;
  min-width: 230px;
  background: white;
  padding: 1rem 0;
  border-radius: 10px;
  overflow: auto;

  .draggable-element {
    border-radius: 0px;
    display: flex;

    &:not(:last-child) {
      border-bottom: 1px solid #f1f1f1;
    }
  }

  .list-group-item {
    padding: 0.4rem 0.4rem 0.4rem 1rem;
    width: 100%;
    border: 0;
    font-size: 0.75rem;
    font-weight: normal !important;
    color: rgb(113, 113, 113) !important;
    width: 100%;
    position: relative;

    &.active {
      background: #f1f1f1;
    }

    .list-group {
      margin-left: 15px;
    }

    .node-icon {
      opacity: 0.5;
      margin-right: 4px;
    }
  }

  .always-on-top-icon,
  .lock-icon {
    cursor: pointer;
    opacity: 0.5;
    margin-right: 4px;

    &.active {
      opacity: 1;
    }
  }

  .always-on-top-icon {
    right: 25px;
    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
}

.ui.page.dimmer {
  z-index: 2000;
}

.folder-list-template {
  .folder-item {
    float: left;
  }
}

.color-picker {
  padding: 0.5rem !important;
  display: flex !important;

  .ui.button {
    color: #2f323a;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
