.album-wizard {
  flex-direction: column-reverse;
  justify-content: flex-end;

  @media (min-width: $breakpoint-desktop) {
    flex-direction: row;
    justify-content: start;
  }
}

.sidebar {
  width: 100%;
  padding-bottom: 4rem;

  @media (min-width: $breakpoint-desktop) {
    height: 100%;
    width: 495px;
    padding: 0;
  }
}

.album-wizard-content {
  display: flex;
  flex-direction: column;

  > div {
    padding: 0.5rem;
  }

  @media (min-width: $breakpoint-desktop) {
    height: 100%;
    width: 100%;
  }
}

.album-preview-container {
  @media (min-width: $breakpoint-desktop) {
    align-items: center;
    display: flex;
    height: 80vh;

    .spread-nav-btn {
      z-index: 1;
    }

    .album-preview-svg-container {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      position: relative;

      svg {
        height: 100%;
        width: 100%;
        position: absolute;
      }
    }

    &:has(.organization-form) {
      justify-content: center;
    }
  }
}

.skip-btn-container {
  background-color: #fff;

  @media (min-width: $breakpoint-desktop) {
    background-color: $light;
  }
}

.wizard-content-head {
  text-align: center;
  background-color: #fff;

  h2.step-title {
    display: inline-block;
    opacity: 0.2;
    font-size: 0.7rem;
    padding: 0 0.75rem;

    &.active {
      font-weight: bold;
      opacity: 1;
      font-size: 1rem;
    }
  }

  @media (min-width: $breakpoint-desktop) {
    background-color: inherit;

    h2.step-title {
      display: inline-block;
      font-weight: bold;
      transition: all 0.5s;
      font-size: 2rem;

      &.active {
        display: inline-block;
        padding: 0 2rem;
        font-size: 2rem;
      }
    }
  }

  .step-content {
    max-width: 700px;
    white-space: pre-wrap;

    @media (min-width: $breakpoint-desktop) {
      width: 80%;
      margin: 1rem 0;
    }
  }
}

.wizard-content-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 11;
  display: flex;
  justify-content: space-between;
  padding: 0rem 1rem 1rem 1rem !important;

  @media (min-width: $breakpoint-desktop) {
    position: static;
  }
}

.styles-selector {
  padding: 1rem 0;

  &:not(:last-child) {
    border-bottom: 2px solid $light;
  }

  .selected-tile {
    padding: 2rem !important;
    height: auto !important;
  }
}

.colors-tile {
  .color-rect {
    height: 50px;
    width: 80px;
  }
}

.fonts-tile {
  font-size: 0.9em;

  .active-fonts-icon {
    path {
      fill: $primary;
    }
  }
}

.fonts-popover,
.colors-popover {
  width: 300px;
  max-height: 95vh;
  overflow-y: scroll;

  .fonts-tile {
    cursor: pointer;
  }

  .colors-tile {
    cursor: pointer;
    justify-content: space-between !important;

    .color-rect {
      width: 60px;
    }
  }

  .colors-tile:not(:last-child),
  .fonts-tile:not(:last-child) {
    border-bottom: 2px solid $light;
  }
}

.dropout-section {
  font-size: 0.9em;

  &:not(:last-child) {
    border-bottom: 2px solid $light;
  }
}

.color-tiles {
  background: #fff;
  height: 40px;

  .color-tile {
    position: relative;

    &:first-child {
      width: 50%;
      border-radius: 0.25em 0 0 0.25em;
    }

    &:nth-child(2) {
      width: 30%;
    }

    &:last-child {
      width: 20%;
      border-radius: 0 0.25em 0.25em 0;
      font-size: 0.8em;
    }
  }

  .magnifier {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    border: 1px solid #fff;
    position: absolute;
    left: 5px;
    top: -30px;

    .icon {
      position: absolute;
      bottom: -18px;
      left: 12px;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  &.active {
    background-color: $primary;
  }

  &--square {
    flex-wrap: wrap;
    height: 5rem;

    .color-tile {
      &:first-child {
        width: 100%;
        border-radius: 0;
      }

      &:nth-child(2) {
        width: 60%;
        border-radius: 0;
      }

      &:last-child {
        width: 40%;
        border-radius: 0;
      }
    }
  }
}

.font-tiles {
  background-color: #fff;

  &.active {
    background-color: $primary;
    border-color: $primary !important;
    color: #fff;
  }

  .tile {
    padding: 0.5em;
    text-align: center;
    width: 100%;
  }

  &--square {
    flex-wrap: wrap;

    .tile {
      padding: 0 5px;
      text-align: left;
    }
  }
}

.sticker-template-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  margin: -16px;
  padding: 20px;
}

.sticker-template-button {
  display: inline-flex;
  aspect-ratio: 5/7;
  border: 4px solid transparent;
  box-sizing: content-box;
  width: 44%;

  &.active {
    border: 4px solid $primary;
    border-radius: 0.25rem;
    position: relative;

    // Checkmark-icon
    .icon {
      position: absolute;
      display: flex;
      color: #fff;
      background-color: $primary;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      right: -12px;
      top: -12px;
    }
  }
}

.sticker-template-view > svg {
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.custom-color-selector {
  .color-container {
    display: flex;
    font-size: 0.9rem;
    background-color: #fff;

    > * {
      color: $secondary;
      width: 100%;
      padding: 8px;
    }

    &.active {
      background-color: $primary;
      border-color: $primary !important;

      > * {
        color: #fff;
      }
    }
  }

  .color-highlight {
    border-radius: 0.25em 0 0 0.25em;
  }
}

.wizard-styles-panel-mobile {
  .style-dropdown {
    background: $white;
    height: 10rem;
    overflow: auto;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .preview-navigator {
    width: 100%;
    max-height: 180px;

    &__wrapper {
      position: sticky;
      top: 0;
      z-index: 0;
      border-bottom: 0;
    }
  }

  &__carousels {
    background-color: white;
    z-index: 1;

    &__label {
      font-size: 0.9em;
    }

    .sticker-template-button {
      width: 100%;
    }
  }
}

.sections-panel {
  .sections-info {
    padding: 0.5rem;
    margin: 0.5rem 0;
  }

  @media (min-width: $breakpoint-desktop) {
    .sections-info {
      padding: 1.5rem;
      margin: 1.5rem 0;
    }
  }
}

.organization-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 400px;
  padding: 40px;
  max-height: 100%;
  max-width: 100%;;

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
  }

  input, select, button {
    min-height: 52px;
  }

  .logo {
    width: 100px;
  }

  .form-label {
    font-weight: 400;
  }

  .label-description {
    line-height: 1;
    font-size: 70%;
  }
}
