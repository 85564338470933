.insert-spread-button {
  cursor: pointer;
  pointer-events: visible;

  &.color-danger {

    .icon {
      shape-rendering: geometricprecision; 
      * {
        fill: tomato !important;
      }
    }

  }

}