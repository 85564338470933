.settings-modal {
  .invite-user-group {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    * {
      width: 100%;
      margin: map-get($spacers, 1) 0;
    }

    @media screen and (min-width: $breakpoint-desktop) {
      flex-direction: row;

      * {
        width: auto;
        margin: 0 map-get($spacers, 1);
      }
    }
  }

  .users-list-item {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    button {
      margin-top: map-get($spacers, 1);
    }

    @media screen and (min-width: $breakpoint-desktop) {
      flex-direction: row;

      button {
        margin-top: 0;
      }
    }
  }
}
