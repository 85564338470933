/*******************************
             Icon
*******************************/

/*--------------
   Font Files
---------------*/

$fontName: 'icons';
$src: url('fonts/#{$fontName}.eot?#iefix') format('embedded-opentype'),
  url('fonts/#{$fontName}.woff2') format('woff2'),
  url('fonts/#{$fontName}.woff') format('woff'),
  url('fonts/#{$fontName}.ttf') format('truetype'),
  url('fonts/#{$fontName}.svg#icons') format('svg');
$fallbackSRC: url('fonts/#{$fontName}.eot');

@font-face {
  font-family: 'Icons';
  src: $fallbackSRC;
  src: $src;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
}

/*--------------
 Optional Files
---------------*/

/* Outline Icons */
$importOutlineIcons: true;
$outlineFontName: 'outline-icons';
$outlineSrc: url('fonts/#{$outlineFontName}.eot?#iefix')
    format('embedded-opentype'),
  url('fonts/#{$outlineFontName}.woff2') format('woff2'),
  url('fonts/#{$outlineFontName}.woff') format('woff'),
  url('fonts/#{$outlineFontName}.ttf') format('truetype'),
  url('fonts/#{$outlineFontName}.svg#icons') format('svg');
$outlineFallbackSRC: url('fonts/#{$outlineFontName}.eot');

/* Load & Define Icon Font */
@font-face {
  font-family: $outlineFontName;
  src: $outlineFallbackSRC;
  src: $outlineSrc;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
}

/* Brand Icons */
$importBrandIcons: false;
$brandFontName: 'brand-icons';
$brandSrc: url('fonts/#{$brandFontName}.eot?#iefix') format('embedded-opentype'),
  url('fonts/#{$brandFontName}.woff2') format('woff2'),
  url('fonts/#{$brandFontName}.woff') format('woff'),
  url('fonts/#{$brandFontName}.ttf') format('truetype'),
  url('fonts/#{$brandFontName}.svg#icons') format('svg');
$brandFallbackSRC: url('fonts/#{$brandFontName}.eot');
/* Load & Define Brand Font */
@font-face {
  font-family: $brandFontName;
  src: $brandFallbackSRC;
  src: $brandSrc;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
}

.highlight-emoji {
  font-size: 3.5rem;
}
