.spread-meta {
  align-items: center;
  display: flex;
  font-size: .8rem;
  justify-content: space-between;
  margin-top: -42px;
  color: $color-medium-gray;

  > div {
    display: flex;
    flex: 1;
    justify-content: center;

    &:first-child {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }

  svg.injected-svg {
    shape-rendering: optimizeSpeed; 
    max-width: 16px;
    max-height: 16px;
    * {
      fill: $color-medium-gray;
    }
  }
}

.danger-zone {
    position: absolute;
    background: crimson;
    opacity: 0.6;
}
