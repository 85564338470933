@use "sass:math";

@import '_fonts';

/// Convert one unit into another
/// @author Hugo Giraudel
/// @param {Number} $value - Initial value
/// @param {String} $unit - Desired unit
/// @return {Number}
/// @throw Error if `$unit` does not exist or if units are incompatible.
@function convert-unit($value, $unit) {
  $units: (
    'px': 0px,
    'cm': 0cm,
    'mm': 0mm,
    '%': 0%,
    'ch': 0ch,
    'in': 0in,
    'em': 0em,
    'rem': 0rem,
    'pt': 0pt,
    'pc': 0pc,
    'ex': 0ex,
    'vw': 0vw,
    'vh': 0vh,
    'vmin': 0vmin,
    'vmax': 0vmax,
    'deg': 0deg,
    'turn': 0turn,
    'rad': 0rad,
    'grad': 0grad,
    's': 0s,
    'ms': 0ms,
    'Hz': 0hz,
    'kHz': 0khz,
    'dppx': 0dppx,
    'dpcm': 0dpcm,
    'dpi': 0dpi,
  );

  @if map-has-key($units, $unit) {
    @return map-get($units, $unit) + $value;
  }

  @error "Unknown unit `#{$unit}`.";
}

@function unit-less($number, $unit: 'rem') {
  @return $number + 0rem;
  @if type-of($number) == 'number' and not unitless($number) {
    @return convert-unit(math.div($number, $number * 0 + 1), $unit);
  }

  @return convert-unit($number, $unit);
}

@mixin loadUIOverrides() {
}

@mixin loadFonts() {
}

/* Global */
$green               : #21BA45;
$lightWeight         : 300;
$lightGrey           : #DCDDDE;
$tabletBreakpoint    : 768px;
$largestMobileScreen : ($tabletBreakpoint - 1px);
$fontName            : 'Rubik', sans-serif;

$sizes: (
  10: 10%,
  15: 15%,
  20: 20%,
  30: 30%,
  40: 40%,
  60: 60%,
  70: 70%,
  80: 80%,
  85: 85%,
  90: 90%,
);

@import 'colors';
@import 'legacy';

// Fix for an incompatibility between semantic and bootstrap
.ui.modal {
  left: auto;
  top: auto;
  width: auto;
  height: auto;
}

/* import bootstrap to set changes */
$popover-max-width: none;

// Define responsive breakpoints
$breakpoint-desktop: 1000px;

@import '~bootstrap/scss/bootstrap';

@import 'overrides';
@import 'app';
@import 'selector';
@import 'element';
@import 'handles';
@import 'editor';
@import 'sidebar';
@import 'toolbar';
@import 'spread';
@import 'insert-spread-button';
@import 'wizard';
@import 'draft';
@import 'sticker-upload-form';
@import 'sticker-form';
@import 'start';
@import 'view-control-menu';
@import 'viewport';
@import 'hints';
@import 'checkout';
@import 'timeline';
@import 'workspace';
@import 'cookie-consent';
@import 'editor_onboarding';
@import 'accept-invitation-form';
@import 'settings-modal';
@import 'multiplayer';
@import 'order-content';
@import 'spread-creation-modal';

.cursor-pointer {
  cursor: pointer;
}

@import 'sticker-uploads-modal';

// Firefox performance fix
svg foreignObject {
  text-rendering: optimizeSpeed;
}
