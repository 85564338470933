.theme_funky {
    --cc-bg: #{$color-background};
    --cc-text: #212529;
    --cc-btn-primary-bg: #{$primary};
    --cc-btn-primary-text: var(--cc-bg);
    --cc-btn-primary-hover-bg: #003264;
    --cc-toggle-bg-off: #8fa8d6;
    --cc-toggle-bg-on: #3859d0;
    --cc-toggle-bg-readonly: #cbd8f1;
    --cc-toggle-knob-bg: #fff;
    --cc-toggle-knob-icon-color: #ecf2fa;
    --cc-block-text: var(--cc-text);
    --cc-cookie-category-block-bg: #ebeff9;
    --cc-cookie-category-block-bg-hover: #dbe5f9;
    --cc-section-border: #f1f3f5;
    --cc-cookie-table-border: #e1e7f3;
    --cc-overlay-bg: rgba(255, 255, 255, .75);
    --cc-webkit-scrollbar-bg: #ebeff9;
    --cc-webkit-scrollbar-bg-hover: #3859d0;

    #c-ttl {
        color: var(--cc-btn-primary-bg);
    }

    #cm,
    #s-bl .act .b-acc,
    #s-inr,
    .cc_div .b-tl,
    .cc_div .c-bl {
        border-radius: 1.2em;
    }

    .cc_div .c-bn {
        border-radius: .7em;
    }

    @media only screen and (max-width: $largestMobileScreen) {
        #cm {
            bottom: 50%;
            transform:translateY(50%) scale(1) !important
        }
    }
}