.multiplayer-highlight {
  overflow: visible;
  height: 10px;

  .avatar {
    width: 9px;
    height: 9px;
    border: 1px solid;
    margin-right: -3px
  }
}
