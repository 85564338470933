/**
* see https://benfrain.com/attempting-to-fix-responsive-svgs-in-desktop-safari/
* for details
*/

.viewport-wrapper {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;

  .viewport {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100vh;
    touch-action: none; // Prevent built-in touch features like zooming
  }
}

.viewport-scrollbar {
  $button-size: 8px; // Size of the scroll-button
  $button-padding: 4px; // Distance from the viewports border

  position: absolute;
  background: transparent;

  .button {
    fill: rgba(0,0,0,0.2);
  }

  &.vertical {
    top: 0;
    right: 0; 
    height: 100%;
    width: $button-size + $button-padding;
    .button {
      width: $button-size;
      ry: $button-size * 0.5;
    }
  }
  
  &.horizontal {
    left: 0;
    bottom: 0;
    height: $button-size + $button-padding;
    width: 100%;
    .button {
      height: $button-size;
      rx: $button-size * 0.5;
    }
  }
}
