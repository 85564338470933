.avatar {
  width: 35px;
  height: 35px;
  background-size: contain;
  background-position: center;
  border-radius: 50%;
  border: 3px solid white;
}

.app-layout {
  min-height: 100%;
}

.card-profile-image {
  position: relative;
}

.card-profile-image img {
  position: absolute;
  left: 50%;
  max-width: 80px;
  transition: all 0.15s ease;
  transform: translate(-50%, -30%);
  border-radius: 0.375rem;
}

.card-profile-image img:hover {
  transform: translate(-50%, -33%);
}

.card-profile-stats {
  padding: 1rem 0;
}

.card-profile-stats > div {
  margin-right: 1rem;
  padding: 0.875rem;
  text-align: center;
}

.card-profile-stats > div:last-child {
  margin-right: 0;
}

.card-profile-stats > div .heading {
  font-size: 1.5rem;
  font-weight: bold;
  display: block;
}

.card-profile-stats > div .description {
  font-size: 0.875rem;
}

.scrollable {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.scrollable::-webkit-scrollbar {
  width: 16px;
}

.scrollable::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 4px solid #f1f1f1;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background: #555;
}
