.workspace {
  display: flex;
  position: relative;
  flex-grow: 1;
  overflow: hidden;

  &.mobile {
    flex: 1 1 auto;
  }
}
