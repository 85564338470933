/* SpreadCreationModal Styles */

// Variables
$thumbnail-size: 3.75rem;
$thumbnail-margin: $spacer * 0.25;

// Mixins
@mixin image-container-base {
  width: $thumbnail-size;
  height: $thumbnail-size;
  margin: $thumbnail-margin;
  border-radius: $border-radius;
}

@mixin selected-card-styles {
  background-color: $gray-300 !important;
  border-width: $border-width * 2;
  border-color: $primary;
  box-shadow: 0 0.25rem 0.5rem rgba($primary, 0.15) !important;
}

// Modal Body
.spread-creation-modal-body {
  max-height: calc(90vh - 9.375rem);
}

// Layout Selection Styles
.layout-card {
  cursor: pointer;
  border-width: $border-width;
  transition: border-color 0.2s, background-color 0.2s, border-width 0.2s, box-shadow 0.2s;
  
  &.selected {
    @include selected-card-styles;
  }
}

// Card inner content background
.card.layout-card.selected {
  background-color: $gray-300 !important;
  
  & > .card-body {
    background-color: $gray-300 !important;
  }
}

// Image Upload Styles
.image-upload-empty-container {
  height: 12.5rem;
}

.drop-area {
  min-height: 15.625rem;
}

// Drag and Drop styles
.highlight {
  background-color: rgba($primary, 0.05) !important;
  border-color: $primary !important;
}

// Common image containers
.image-thumbnail,
.custom-drag-preview,
.image-placeholder {
  @include image-container-base;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// Image thumbnail
.image-thumbnail {
  position: relative;
  flex-shrink: 0;
  cursor: move;
  transition: transform 0.2s ease, box-shadow 0.2s ease, opacity 0.2s ease;
  
  &.dragging {
    transform: scale(1.05);
    box-shadow: $box-shadow-sm;
    opacity: 0.4;
  }
}

// Drag preview
.custom-drag-preview {
  background-color: $white;
  box-shadow: $box-shadow;
  transform: rotate(-5deg);
  opacity: 0.9;
  z-index: $zindex-dropdown;
  border: 2px solid $primary;
}

// Empty placeholder
.image-placeholder {
  border: 1px dashed $gray-300;
  background-color: $gray-100;
  color: $gray-500;
}
